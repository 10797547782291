import React, { createContext, useState, useEffect, useContext } from "react";

// Create a context for isLoggedIn
const IsLoggedInContext = createContext();

// Custom hook to access the isLoggedIn value
export const useIsLoggedIn = () => useContext(IsLoggedInContext);

// Provide the value of isLoggedIn throughout the app
export const IsLoggedInProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Check if the user is logged in on component mount
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    setIsLoggedIn(accessToken ? true : false);
  }, []);

  return (
    <IsLoggedInContext.Provider value={isLoggedIn}>
      {children}
    </IsLoggedInContext.Provider>
  );
};
