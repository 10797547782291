import React, { useEffect, useState } from "react";
import MovieCard from "../components/MovieCard";
import SeriesCard from "../components/SeriesCard";
import telegramBanner from "../../assets/images/telegram_banner.gif"
import { Pagination } from "@mui/material";
import AdsScript from "../components/AdsScript";


const Home = ({ loadingMovie }) => {
  const [movielist, setMovieList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [isPageLoading, setIsPageLoading] = useState(false); // State for page loading indicator

  const itemsPerPage = 30; // Matches the page size defined on the backend

  const fetchmovies = `${process.env.REACT_APP_API_URL}/fetch-homepage-movies/?page=${currentPage}`;

  loadingMovie = isLoading;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(fetchmovies);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setMovieList(data.results);
        setIsLoading(false);
        setIsPageLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("An error occurred:", error);
        setIsPageLoading(false); // Set loading to false if there's an error
      }
    };

    fetchData();
  }, [fetchmovies, currentPage]);

  const handleSearch = () => {
    window.location.href = `/search?q=${searchQuery}`;
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    setIsPageLoading(true); // Set isPageLoading to true when changing the page
  };

  return (
    <>
          {/* <AdsScript src="https://alwingulla.com/88/tag.min.js" zone="52356" /> */}
    <div className="container mx-auto px-4 min-h-[80vh]">
        <div className="flex flex-col items-center justify-center my-3">
          <a href="https://t.me/movisng" target="_blank" >
            <img src={telegramBanner} className="rounded-lg" />
          </a>
        </div>
      <div className="flex flex-col items-center justify-center my-3">
        <div className="flex items-center mt-3">
          <input
            type="text"
            placeholder="Search"
            className="input input-bordered mr-2 w-[80vw] md:w-[50vw] mx-4 text-darkColor placeholder-gray-400"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button
            className="btn bg-primaryColor border-none"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>
      </div>
      <div className="">
        <div className="flex flex-col items-center justify-center my-3">
          {/* <h5 className="text-2xl font-bold text-white my-3"> </h5> */}
        </div>
        {!isLoading ? (
          <div>
            <div className="flex flex-col items-center justify-center my-3">
              <div className="flex flex-wrap gap-2 items-center justify-center">
              {/* <AdsScript /> */}
                {movielist.map((movie) => {
                  // Check if movie_type exists and is 'movie'
                  if (movie.movie_type && movie.movie_type === "movie") {
                    return (
                      <MovieCard
                        key={movie.id}
                        id={movie.id}
                        title={movie.name}
                        poster={movie.image_url}
                        movieType={movie.movie_type}
                      />
                    );
                  } else {
                    return (
                      <SeriesCard
                        key={movie.id}
                        id={movie.id}
                        title={movie.name}
                        poster={movie.image_url}
                        movieType={movie.movie_type}
                      />
                    );
                  }
                })}
              </div>

              <div className="flex flex-col items-center justify-center my-2">
              {/* {isPageLoading && (
                    <div className="flex items-center justify-center">
                      <span className="loading loading-spinner text-warning text-6xl"></span>
                    </div>
                  )} */}
  <Pagination
    count={Math.ceil(movielist.count / itemsPerPage)}
    page={currentPage}
    onChange={handlePageChange}
    color="primary"
    loading={isPageLoading}
    loadingIndicator={<div className="flex items-center justify-center"><span className="loading loading-spinner text-warning text-6xl"></span></div>} // Custom loading indicator
    showSizeChanger
    showFirstButton
    showLastButton
    sx={{
      "& .MuiPaginationItem-root": {
        color: "orange",
      },
      "& .MuiPaginationItem-page.Mui-selected": {
        backgroundColor: "orange",
        color: "black",
      },
    }}
  />
</div>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center">
            <span className="loading loading-spinner text-warning text-6xl"></span>
          </div>
        )}
      </div>
    </div>
    </>

  );
};

export default Home;
