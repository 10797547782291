import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import SeriesCard from "../components/SeriesCard";
import { Helmet } from "react-helmet";
import telegramBanner from "../../assets/images/telegram_banner.gif"
import AdsScript from "../components/AdsScript";


const Series = () => {
  const [seriesList, setSeriesList] = useState({ results: [], count: 0 }); // Initialize with an empty list and count
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const itemsPerPage = 30; // Matches the page size defined on the backend

  const fetchseries = `${process.env.REACT_APP_API_URL}/fetch-series/`;

  useEffect(() => {
    // Function to fetch series based on the page number
    const fetchSeries = async (page) => {
      try {
        // Make a GET request to the backend API with the page query parameter
        const response = await fetch(`${fetchseries}?page=${page}`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setSeriesList(data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    // Fetch series for the initial page
    fetchSeries(currentPage);
  }, [currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
          {/* <AdsScript src="https://alwingulla.com/88/tag.min.js" zone="52356" /> */}
      <Helmet>
        <title>TV Series - Movis.ng</title>
      </Helmet>
      <div className="container mx-auto px-4 min-h-[80vh]">
      <div className="flex flex-col items-center justify-center my-3">
          <a href="https://t.me/movisng" target="_blank">
          <img src={telegramBanner} className="rounded-lg" />
          </a>
        </div>
        <div className="flex flex-col items-center justify-center my-3">
          <h5 className="text-2xl font-bold text-white my-3">Series</h5>
          {isLoading ? (
            <div className="flex items-center justify-center">
  <span className="loading loading-spinner text-warning text-6xl"></span>
</div>          ) : (
            <>
              <div className="flex flex-wrap gap-2 items-center justify-center">
                {seriesList.results.map((movie) => (
                  <SeriesCard
                    key={movie.id}
                    id={movie.id}
                    title={movie.name}
                    poster={movie.image_url}
                    movieType={movie.movie_type}
                  />
                ))}
              </div>
              <div className="flex flex-col items-center justify-center my-2">
                <Pagination
                  count={Math.ceil(seriesList.count / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  sx={{
                    "& .MuiPaginationItem-root": {
                      color: "orange",
                    },
                    "& .MuiPaginationItem-page.Mui-selected": {
                      backgroundColor: "orange",
                      color: "black",
                    },
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Series;
