import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import MovieCard from "../components/MovieCard";
import { Helmet } from "react-helmet";
import telegramBanner from "../../assets/images/telegram_banner.gif";
import AdsScript from "../components/AdsScript";

const Movies = () => {
  const [movielist, setMovieList] = useState({ results: [], count: 0 }); // Initialize with an empty list and count
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const itemsPerPage = 30; // Matches the page size defined on the backend

  useEffect(() => {
    // Define the URL for fetching movies from your backend
    const apiUrl = `${process.env.REACT_APP_API_URL}/fetch-movies/`;

    // Function to fetch movies based on the page number
    const fetchMovies = async (page) => {
      try {
        // Make a GET request to the backend API with the page query parameter
        const response = await fetch(`${apiUrl}?page=${page}`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setMovieList(data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    // Fetch movies for the initial page
    fetchMovies(currentPage);
  }, [currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
          {/* <AdsScript src="//deafeningphone.com/bEXWV.s/dZGflz0LYKWCdxiwYCWj5/ufZfXBIT/aefmK9hudZ/U_lsk_PrTPUW0qO/DrE/1MMIjWQGteNlTIQE4UMzTOUmyeNBQb" /> */}
      <Helmet>
        <title>Movies - Movis.ng</title>
      </Helmet>
      <div className="container mx-auto px-4 min-h-[80vh]">
        <div className="flex flex-col items-center justify-center my-3">
          <a href="https://t.me/movisng" target="_blank">
          <img src={telegramBanner} className="rounded-lg" />
          </a>
        </div>
        <div className="flex flex-col items-center justify-center my-3">
          <h5 className="text-2xl font-bold text-white my-3">Movies</h5>
          {isLoading ? (
            <div className="flex items-center justify-center">
              <span className="loading loading-spinner text-warning text-6xl"></span>
            </div>
          ) : (
            <>
              <div className="flex flex-wrap gap-2 items-center justify-center">
                {movielist.results.map((movie) => (
                  <MovieCard
                    key={movie.id}
                    id={movie.id}
                    title={movie.name}
                    poster={movie.image_url}
                    movieType={movie.movie_type}
                  />
                ))}
              </div>
              <div className="flex flex-col items-center justify-center my-2">
                <Pagination
                  count={Math.ceil(movielist.count / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  sx={{
                    "& .MuiPaginationItem-root": {
                      color: "orange",
                    },
                    "& .MuiPaginationItem-page.Mui-selected": {
                      backgroundColor: "orange",
                      color: "black",
                    },
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Movies;
