import React, { useState } from "react";
import AddMovieModal from "./AddMovieModal";
import { PlusCircle } from "react-feather";
import logo from "../../assets/images/movis_logo.png";
import { useIsLoggedIn } from "../components/isLoggedIn";

function Navbar() {
  const isLoggedIn = useIsLoggedIn();
  const [openForm, setOpenForm] = React.useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleSearch = () => {
    window.location.href = `/search?q=${searchQuery}`;
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      {openForm && (
        <AddMovieModal
          openModal={handleOpenForm}
          closeModal={handleCloseForm}
        />
      )}
      <div className="navbar bg-darkColor shadow-md">
        <div className="navbar-start">
          <div className="dropdown">
            <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-black w-52"
            >
              <li>
                <a
                  href="/"
                  className="text-white text-base hover:text-primaryColor bold"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/movies"
                  className="text-white text-base hover:text-primaryColor bold"
                >
                  Movies
                </a>
              </li>
              <li>
                <a
                  href="/series"
                  className="text-white text-base hover:text-primaryColor bold"
                >
                  TV Series
                </a>
              </li>
            </ul>
          </div>
          <div className="m-auto">
            <a href="/">
              <img src={logo} />
            </a>
          </div>
        </div>
        <div className="navbar-center hidden lg:flex">
          <ul className="menu menu-horizontal px-1 ">
            <li>
              <a
                href="/"
                className="text-white text-base hover:text-primaryColor bold"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="/movies"
                className="text-white text-base hover:text-primaryColor bold"
              >
                Movies
              </a>
            </li>
            <li>
              <a
                href="/series"
                className="text-white text-base hover:text-primaryColor bold"
              >
                TV Series
              </a>
            </li>
          </ul>
        </div>
        <div className="navbar-end">
          <div className="form-control">
            <input
              type="text"
              placeholder="Search"
              className="input input-bordered w-40 md:w-auto text-darkColor placeholder-gray-400"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </div>
          {isLoggedIn ? (
            <div className="mx-2">
              <PlusCircle onClick={handleOpenForm} className="cursor-pointer" />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
export default Navbar;