import logo from "./logo.svg";
import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import AppRouter from "./router";
import Navbar from "./views/components/Navbar";
import { Helmet } from "react-helmet";
import Footer from "./views/components/Footer";

function App() {
  return (
    <div>
      <Helmet>
        <title>Movis.ng - Download Movies Online for Free</title>
      </Helmet>
      <Navbar />
      <AppRouter />
      <Footer />
       
    </div>
  );
}

export default App;
