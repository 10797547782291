import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Search } from "@mui/icons-material";
import { Plus, X } from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const AddMovieModal = ({ openModal, closeModal, download_url }) => {
  const classes = useStyles();

  const [movieFormData, setMovieFormData] = useState({
    name: "",
    description: "",
    imageUrl: "",
    trailerUrl: "",
    downloadUrl: "",
    genres: [],
    releaseDate: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [formData, setFormData] = useState({});
  const [downloadURL, setDownloadURL] = useState("");
  const [trailer, setTrailer] = useState();
  const { movieId } = useParams();
  const [movieType, setMovieType] = useState("");
  const [movieCategory, setMovieCategory] = useState("");
  const [trending, setTrending] = useState(false);

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const key = process.env.REACT_APP_TMDB_KEY;

  const api =
    movieType == "movie"
      ? `https://api.themoviedb.org/3/movie/${searchQuery}?api_key=${key}&append_to_response=videos`
      : `https://api.themoviedb.org/3/tv/${searchQuery}?api_key=${key}&append_to_response=videos`;

  const handleSearch = () => {
    axios
      .get(api)
      .then((response) => {
        const fetchedMovieData = response.data;
        setMovieFormData(fetchedMovieData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const moviePoster = movieFormData.poster_path
    ? `https://image.tmdb.org/t/p/original${movieFormData.poster_path}`
    : "";

  const genres = movieFormData.genres || [];

  const trailerURL = movieFormData.videos;

  let officialTrailerKey = "";

  if (trailerURL && trailerURL.results) {
    const officialTrailer = trailerURL.results.find((trailer) =>
      trailer.name.toLowerCase().includes("official")
    );

    if (officialTrailer) {
      officialTrailerKey = officialTrailer.key;
    }
  }

  const handleChange = (e) => {
    setMovieFormData({
      ...movieFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDownloadUrlChange = (e) => {
    setFormData({
      ...formData,
      downloadUrl: e.target.value,
    });
  };

  const mappedFormData = {
    name: movieType == "movie" ? movieFormData.title : movieFormData.name,
    description: movieFormData.overview,
    image_url: moviePoster,
    trailer_url: officialTrailerKey
      ? `https://www.youtube.com/embed/${officialTrailerKey}`
      : "",
    download_url: formData.downloadUrl,
    release_date: movieFormData.release_date,
    genres: movieFormData.genres.map((genre) => genre.name),
    movie_type: movieType,
    trending: trending,
    category: movieCategory,
  };

  const mappedSeriesData = {
    series:
 {   name: movieFormData.name,
    description: movieFormData.overview,
    image_url: moviePoster,
    trailer_url: officialTrailerKey
      ? `https://www.youtube.com/embed/${officialTrailerKey}`
      : "",
    download_url: formData.downloadUrl,
    release_date: movieFormData.release_date,
    // genres: movieFormData.genres.map((genre) => genre.name),
    movie_type: movieType,
    trending: trending,
    category: movieCategory,}
  };

  const postapi = `${process.env.REACT_APP_API_URL}/add-movies/`;
  const postseries = `${process.env.REACT_APP_API_URL}/add-series/`;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make a POST request to the backend API
      const response = await fetch(postapi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mappedFormData),
      });

      if (!response.ok) {
        // If the response status is not within the range 200-299, it's considered an error
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      // If the response is successful, show success toast and handle any necessary actions
      // For example:
      const data = await response.json();
      toast.success("Movie Added successfully!");
      setFormData("");
      // closeModal();
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    } catch (error) {
      toast.error("Movie was not added. Error: " + error);
    }
  };
  const handleSeriesSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make a POST request to the backend API
      const response = await fetch(postapi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mappedSeriesData),
      });

      if (!response.ok) {
        // If the response status is not within the range 200-299, it's considered an error
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      // If the response is successful, show success toast and handle any necessary actions
      // For example:
      const data = await response.json();
      toast.success("Movie Added successfully!");
      setFormData("");
      // closeModal();
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    } catch (error) {
      toast.error("Movie was not added. Error: " + error);
    }
  };

    const handleMovieType = (event) => {
    setMovieType(event.target.value);
  };
  const handleMovieCategory = (event) => {
    setMovieCategory(event.target.value);
  };

  const handleTrendingChange = (event) => {
    setTrending(event.target.checked); 
  };

  const handleSubmitSeries = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(postseries, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mappedSeriesData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      const data = await response.json();
      toast.success("Series Added successfully!");

    } catch (error) {
      toast.error("Series was not added. Error: " + error);
    }
  };

  return (
    <>
      <ToastContainer />
      {movieFormData ? (
        <Dialog open={openModal} onClose={closeModal} maxWidth="lg">
          <div className={classes.modal}>
            <div>
              <DialogTitle>Add New Movie</DialogTitle>
              <DialogContent>
                <div className={classes.searchField}>
                  <FormControl sx={{ color: "white" }}>
                    <TextField
                      type="text"
                      size="small"
                      placeholder="Enter Movie ID"
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </div>
                {movieFormData && (
                  <div className={classes.formFields}>
                    <FormControl sx={{ marginTop: "10px" }}>
                    <InputLabel>Select Type</InputLabel>
                      <Select
                        value={movieType}
                        onChange={handleMovieType}
                        sx={{ width: "200px" }}
                        placeholder="Type"
                        required
                      >
                        <MenuItem value="movie">Movie</MenuItem>
                        <MenuItem value="series">Series</MenuItem>
                      </Select>
                  <IconButton
                    onClick={handleSearch}
                    sx={{
                      color: "white",
                      backgroundColor: "orange",
                      borderRadius: "0px",
                      width: "200px",
                      my: "10px"
                    }}
                  >
                    <Search />
                  </IconButton>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={trending}
                            onChange={handleTrendingChange}
                          />
                        }
                        label="Trending"
                      />
                    </FormControl>
                    <FormControl sx={{ marginTop: "10px" }}>
                      <InputLabel>Select Category</InputLabel>
                      <Select
                        value={movieCategory}
                        onChange={handleMovieCategory}
                        sx={{ width: "200px" }}
                        placeholder="Type"
                        required
                      >
                        <MenuItem value="hollywood">Hollywood</MenuItem>
                        <MenuItem value="k-drama">K-Drama</MenuItem>
                        <MenuItem value="bollywood">Bollywood</MenuItem>
                        <MenuItem value="nollywood">Nollywood</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <TextField
                        type="text"
                        size="small"
                        name={movieType == "movie" ? "title" : "name"}
                        placeholder="Movie Name"
                        value={movieType == "movie" ? movieFormData.title : movieFormData.name}
                        onChange={handleChange}
                      />
                    </FormControl>

                    <FormControl>
                      <TextField
                        type="text"
                        name="description"
                        size="small"
                        placeholder="Movie Description"
                        value={movieFormData.overview}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        type="text"
                        name="release_date"
                        size="small"
                        placeholder="Release Date"
                        value={movieFormData.release_date}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        type="text"
                        size="small"
                        name="imageUrl"
                        placeholder="Movie Image Path"
                        value={moviePoster}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <Select
                        multiple
                        value={movieFormData.genres.map((genre) => genre.id)}
                        renderValue={(selected) =>
                          selected
                            .map((value) => {
                              const genre = genres.find(
                                (genre) => genre.id === value
                              );
                              return genre ? genre.name : "";
                            })
                            .join(", ")
                        }
                        inputProps={{
                          name: "genres",
                          id: "genres-select",
                        }}
                        onChange={handleChange}
                      >
                        {genres.map((genre) => (
                          <MenuItem key={genre.id} value={genre.id}>
                            {genre.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <TextField
                        type="text"
                        size="small"
                        name="trailerUrl"
                        placeholder="Trailer URL"
                        value={
                          officialTrailerKey
                            ? `https://www.youtube.com/embed/${officialTrailerKey}`
                            : ""
                        }
                        onChange={handleChange}
                      />
                    </FormControl>

                    <FormControl>
                      <TextField
                        type="text"
                        size="small"
                        name="downloadUrl"
                        value={formData.downloadUrl}
                        placeholder="Download URL"
                        onChange={handleDownloadUrlChange}
                      />
                    </FormControl>
                  </div>
                )}
              </DialogContent>
              <DialogActions
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <Button
                  sx={{
                    color: "red",
                    border: "red solid 1px",
                  }}
                  onClick={closeModal}
                  startIcon={<X size={"15px"} />}
                >
                  Close
                </Button>
                <Button
                  sx={{
                    color: "white",
                    backgroundColor: "orange",
                  }}
                  onClick={
                    movieType == "series" ? handleSubmitSeries : handleSubmit
                  }
                  endIcon={<Plus size={"15px"} />}
                >
                  Add Movie
                </Button>
              </DialogActions>
            </div>
          </div>
        </Dialog>
      ) : (
        <CircularProgress size={20} />
      )}
    </>
  );
};

export default AddMovieModal;

const useStyles = makeStyles({
  modal: {
    width: "30vw",
  },
  searchField: {
    display: "flex",
    flexDirection: "row",
  },
  formFields: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      marginTop: "15px !important",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
});
