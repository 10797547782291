import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import { useParams } from "react-router-dom";
import { X } from "react-feather";

const TrailerModal = ({ openModal, closeModal, downloadLink }) => {
  const classes = useStyles();

  const [trailer, setTrailer] = useState();

  const { movieId, name } = useParams();

  const key = process.env.REACT_APP_TMDB_KEY;
  const api = `https://api.themoviedb.org/3/movie/${movieId}/videos?api_key=${key}`;

  const fetchapi = `${process.env.REACT_APP_API_URL}/movie/${movieId}/${name}/`;

  useEffect(() => {
    fetch(fetchapi)
      .then((response) => response.json())
      .then((data) => {
        setTrailer(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [fetchapi]);

  return (
    <>
      <div>
        <Dialog open={openModal} onClose={closeModal}>
          {trailer ? (
            <div className={classes.modal}>
              <DialogTitle>TRAILER: {trailer.name}</DialogTitle>
              <DialogContent className={classes.video}>
                <iframe
                  width="560"
                  height="315"
                  src={trailer.trailer_url}
                  title={trailer.name}
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </DialogContent>
              <DialogActions
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <Button
                  onClick={closeModal}
                  sx={{
                    color: "red",
                    border: "red solid 1px",
                  }}
                  startIcon={<X size={"15px"} />}
                >
                  Close
                </Button>
                {/* <Button
                  sx={{
                    color: "white",
                    backgroundColor: "orange",
                  }}
                  onClick={downloadLink}
                >
                  Download Movie
                </Button> */}
              </DialogActions>
            </div>
          ) : (
            <div className="flex items-center justify-center">
              <span className="loading loading-spinner text-warning text-6xl"></span>
            </div>
          )}
        </Dialog>
      </div>
    </>
  );
};

export default TrailerModal;

const useStyles = makeStyles({
  dialogActions: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
  },
  video: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
  },
});
