import { useEffect, useState } from "react";
import "./style.css";
import { makeStyles } from "@material-ui/styles";
import Body from "./Body";
// import Navbar from "../components/Navbar";
import Home from "../pages/Home";
import MovieDetails from "../pages/MovieDetails";
import axios from "axios";
import MovieCard from "../components/MovieCard";
import MockData from "../components/MockData";

function Layout() {
  const [movies, setMovies] = useState()
  const classes = useStyles();

  const url = process.env.REACT_APP_API_URL + '/movies/latest'

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/movies/add_movie`);
        setMovies(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, []);
  

  return (
    <div className="container">

      <div>
        <MovieDetails />
      </div>
      <div>
        {MockData.map((data => (
      <MovieCard key={data.id} title={data.title} poster={data.poster}/>
      )))}
      </div>
    </div>
  );
}
export default Layout;

const useStyles = makeStyles({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  },
});
