import React from "react";
import { Link } from "react-router-dom";
import loadingImage from "../../assets/images/loading-image.jpg"


const SeriesCard = ({ poster, title, id, movieType }) => {

  return (
    <div className="w-40 md:w-auto lg:w-auto">
      <Link to={`/series/${id}/${title}/`}>
        <div className="relative w-full rounded-lg shadow-2xl">
        <img src={poster ? poster : loadingImage} alt="" className="min-w-40 h-[28vh] rounded-lg shadow-sm" />
          <div className="absolute top-0 left-0 m-1">
            <span className="bg-red-600 text-white text-xs px-2 py-1 rounded-md">
              {movieType}
            </span>
          </div>
          <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 p-4 rounded-lg">
            <p className="text-white text-14px font-bold text-center">
              {title}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SeriesCard;
