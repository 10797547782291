import React from "react";
import MovieCard from "../components/MovieCard";
import "./style.css";
import { makeStyles } from "@material-ui/styles";
import MockData from "../components/MockData";

const Body = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {MockData.map((data) => (
        <MovieCard key={data.id} title={data.title} poster={data.poster}/>
      ))}
    </div>
  );
};
//map data
export default Body;

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    flexWrap: "wrap",
  },
});
