import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Download } from 'react-feather';
import { DownloadDone } from '@mui/icons-material';

function EpisodesList({ seasonNumber, episodeNumbers, episodeDownloadLink, seasonTitle, hasEpisode, isFirstSeason, onAccordionChange }) {
  // Combine episode numbers and download links into an array of objects
  const episodeData = episodeNumbers.map((episodeNumber, index) => ({
    episodeNumber,
    downloadLink: episodeDownloadLink[index],
  }));

  return (
    <Accordion  className=" w-[90%] bg-primaryColor border-none my-1" expanded={isFirstSeason} onChange={(_, isExpanded) => onAccordionChange(isExpanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ width: '100%', backgroundColor: "#FFA500", height: "5px" }}>
      <a href="#" class="flex items-center text-14 font-bold text-black my-3">
    <span class="mr-2"><Download /></span> Download Season {seasonTitle}
</a>      </AccordionSummary>
      <AccordionDetails>
        <div className=" w-full bg-[#f1f1f1]">
          {episodeData.map((episode) => (
            <div key={episode.episodeNumber} className="bg-white flex justify-between items-center mb-2 px-1 py-0">
              <p className="text-14 text-black my-3">
                Episode {episode.episodeNumber}
              </p>
              <a href={episode.downloadLink} target='_blank' className="btn bg-primaryColor border-none">
                Download
              </a>
            </div>
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
}


export default EpisodesList;
