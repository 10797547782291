import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { CircularProgress, Typography } from "@mui/material";
import MockData from "../components/MockData";
import MovieCard from "../components/MovieCard";
import Sections from "../components/Section";
import RecentlyAdded from "../components/RecentlyAdded";
import SeriesCard from "../components/SeriesCard";

const SearchPage = () => {
  const classes = useStyles();

  const searchParams = new URLSearchParams(window.location.search);
  const searchQuery = searchParams.get("q");
  const [searchResults, setSearchResults] = useState([]);
  const [resultLoading, setResultLoading] = useState(true)

  const [movieDetails, setMoviesDetails] = useState([]);

  const key = process.env.REACT_APP_TMDB_KEY;

  const searchapi = `${process.env.REACT_APP_API_URL}/search/?name=${searchQuery}`;


  useEffect(() => {
    // Fetch search results based on the search query
    const fetchSearchResults = async () => {
      try {
        const response = await fetch(searchapi);
        const data = await response.json();
        const moviesResult = data.movies
        const seriesResult = data.series
        const combinedResults = [...moviesResult, ...seriesResult];
        setSearchResults(combinedResults);
        setResultLoading(false)
      } catch (error) {
        console.error(error);
      }
    };

    if (searchQuery) {
      fetchSearchResults();
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);


  return (
    <div>
    <div className={classes.container}>
      <Typography variant="h6" sx={{marginBottom: '10px'}}>Search Result</Typography>

      {searchResults.length > 0 ? (
        <div className={classes.moviesContainer}>
          {searchResults.map((movie) => (
            <>
            {movie.movie_type === "series" ? (
              <SeriesCard
              key={movie.id}
              id={movie.id}
              title={movie.name}
              poster={movie.image_url}
              movieType={movie.movie_type}
            />
            
            ) : 
            <MovieCard
              key={movie.id}
              id={movie.id}
              title={movie.name}
              poster={movie.image_url}
              movieType={movie.movie_type}
            />
            }
            </>
          ))}
        </div>
      ) : (<Typography>{resultLoading ? "Searching..." :  "No Result"}
      </Typography>)}
    </div>
    <RecentlyAdded />
    </div>
  );
};
export default SearchPage;

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "10px",
    margin: "15px",
  },
  moviesContainer: {
    width: "100vw",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    flexWrap: "wrap",
  },
});
