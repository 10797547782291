import React, { useState } from "react";
import {
  Button,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import TextField from "@mui/material/TextField";
import {
  FormControl,
} from "@mui/material";
import { LogIn } from "react-feather";
import Navbar from "./Navbar";
import Home from "../pages/Home";

const Login = () => {
  const classes = useStyles();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const apiUrl = `${process.env.REACT_APP_API_URL}/api/login/`;

  const handleFormSubmit = async (event) => {
    event.preventDefault();
  
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      });
  
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('accessToken', data.access);  // Store the access token
        localStorage.setItem('refreshToken', data.refresh);  // Store the refresh token
        setIsLoggedIn(true);
        window.location.href = "/";
      } else {
        console.error("Login failed");
      }
    } catch (error) {
      console.error(error);
    }
  };
  

  const getAuthHeaders = () => {
    const accessToken = localStorage.getItem('accessToken');
    return {
      'Authorization': `Bearer ${accessToken}`
    };
  };

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl, {
        headers: {
          ...getAuthHeaders(),
          'Content-Type': 'application/json'
        }
      });
  
      if (response.ok) {
        console.log('LOGGED IN');
      } else if (response.status === 401) {
        console.log('Unauthorized');
      } else {
        console.log('Not logged');

      }
    } catch (error) {
      console.error(error);
    }
  };
  

  return (
    <>
          {isLoggedIn && <Navbar isLoggedIn={isLoggedIn} />}
      <div className={classes.container}>
        <div className={classes.form}>
          <div>
            <Typography
              variant="h6"
              sx={{
                color: "black",
                marginBottom: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Login
            </Typography>
            <div className={classes.formFields}>
              <FormControl>
                <TextField
                  type="text"
                  size="small"
                  name="username"
                  placeholder="Enter Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <TextField
                  type="password"
                  name="password"
                  size="small"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
            </div>
            <div>
              <Button
                sx={{
                  color: "white",
                  backgroundColor: "orange",
                  marginTop: "10px",
                  float: "right",
                }}
                endIcon={<LogIn size={"15px"} />}
                onClick={handleFormSubmit}
              >
                Login
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* </Dialog> */}
    </>
  );
};

export default Login;

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    padding: "50px",
    width: "250px",
    alignItems: "center",
  },
  formFields: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    "& > *:not(:last-child)": {
      marginBottom: "20px", // Add margin-bottom to create space between form fields
    },
    "& > *": {
      width: "300px", // Set the width of form fields to 100%
    },
  },
});
