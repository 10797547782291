import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { CircularProgress, Pagination } from "@mui/material";
import MovieCard from "../components/MovieCard";
import { Helmet } from "react-helmet";
import SeriesCard from "../components/SeriesCard";

const KDrama = () => {
  const classes = useStyles();
  const [movieDetails, setMoviesDetails] = useState([]);
  const [movielist, setMovieList] = useState([]);
  const [seriesList, setSeriesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const itemsPerPage = 30;
  const categoryFilter = "k-drama"; // Define the category to filter
  const pageTitle = "KDrama"

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/fetch-movies/`;

    const fetchMovies = async () => {
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        // Filter the data by movie_type and category
        const filteredMovies = data.results.filter((movies) => {
          return (
            movies.movie_type === "movie" && movies.category === categoryFilter
          );
        });

        setMovieList(filteredMovies);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchMovies();
  }, []);

  useEffect(() => {
    const fetchseries = `${process.env.REACT_APP_API_URL}/fetch-series/`;

    const fetchSeries = async () => {
      try {
        const response = await fetch(fetchseries);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        // Filter the data by movie_type and category
        const filteredSeries = data.results.filter((series) => {
          return (
            series.movie_type === "series" && series.category === categoryFilter
          );
        });

        setSeriesList(filteredSeries);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchSeries();
  }, []);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const mergedList = [...seriesList, ...movielist];

  // Sort the merged list by updated_at for series and created_at for movies in descending order (most recent first)
  const sortedList = mergedList.sort((a, b) => {
    const dateA = new Date(a.updated_at || a.created_at);
    const dateB = new Date(b.updated_at || b.created_at);
    return dateB - dateA;
  });
  
  const filteredMovies = sortedList;
  const totalPages = Math.ceil(filteredMovies.length / itemsPerPage);
  
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentMovies = filteredMovies.slice(startIndex, endIndex);

  return (
    <>
      <Helmet>
        <title>{pageTitle} - Movis.ng</title>
      </Helmet>
      <div className={classes.container}>
        <div>
          <h2 style={{ color: "#FFA500", textAlign: "center" }}>
            {pageTitle} Movies
          </h2>
          {!isLoading ? (
            <>
              <div className={classes.moviesContainer}>
                {currentMovies.map((movie) => (
                  <>
                    {movie.movie_type === "movie" ? (
                      <MovieCard
                        key={movie.id}
                        id={movie.id}
                        title={movie.name}
                        poster={movie.image_url}
                        movieType={movie.movie_type}
                      />
                    ) : (
                      <SeriesCard
                        key={movie.id}
                        id={movie.id}
                        title={movie.name}
                        poster={movie.image_url}
                        movieType={movie.movie_type}
                      />
                    )}
                  </>
                ))}
              </div>
              <div className={classes.pagination}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  sx={{
                    "& .MuiPaginationItem-root": {
                      color: "orange",
                    },
                    "& .MuiPaginationItem-page.Mui-selected": {
                      backgroundColor: "orange",
                      color: "black",
                    },
                  }}
                />
              </div>
            </>
          ) : (
            <CircularProgress size={20} />
          )}
        </div>
      </div>
    </>
  );
};

export default KDrama;

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0",
    margin: "0",
  },
  moviesContainer: {
    width: "100vw",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    flexWrap: "wrap",
  },
  trendingMovies: {
    backgroundColor: "red",
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
  },
});
