import { React, useEffect, useState } from "react";
import MovieCard from "../components/MovieCard";
import { makeStyles } from "@material-ui/styles";
import MockData from "../components/MockData";
import {
  useMediaQuery,
  useTheme,
} from "@mui/material";
import TrailerModal from "../components/TrailerModal";
import { useParams } from "react-router-dom";
import { PlayCircle } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import EpisodesList from "../components/EpisodesList";
import { Plus } from "react-feather";
import AddSeriesModal from "../components/AddSeriesModal";
import { useIsLoggedIn } from "../components/isLoggedIn";
import telegramBanner from "../../assets/images/telegram_banner.gif";
import AdsScript from "../components/AdsScript";

const SeriesDetails = ({ download_url }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isScreenSizeSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);
  const [seriesPageDetails, setSeriesPageDetails] = useState([]);
  const [seasonsList, setSeasonsList] = useState([]);
  const [episodesList, setEpisodesList] = useState([]);
  const [expandedAccordions, setExpandedAccordions] = useState([1]);
  const [openForm, setOpenForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { seriesId, name, seasonId } = useParams();

  const isLoggedIn = useIsLoggedIn();

  const key = process.env.REACT_APP_TMDB_KEY;

  // const api = `https://api.themoviedb.org/3/movie/${movieId}?api_key=${key}`;

  const fetchdetails = `${process.env.REACT_APP_API_URL}/fetch-series-details/${seriesId}/${name}/`;
  const fetchseasons = `${process.env.REACT_APP_API_URL}/fetch-seasons/${seriesId}/`;

  console.log(seriesPageDetails.image_url);

  const url = `https://movis.ng/series/${seriesId}/${name}/`;

  useEffect(() => {
    fetch(fetchdetails)
      .then((response) => response.json())
      .then((data) => {
        setSeriesPageDetails(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [fetchdetails]);

  useEffect(() => {
    fetch(fetchseasons)
      .then((response) => response.json())
      .then((data) => {
        setSeasonsList(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [seriesId, seasonId]);

  const refetchContent = () => {
    fetch(fetchseasons)
      .then((response) => response.json())
      .then((data) => {
        setSeasonsList(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    refetchContent();
  };

  const handleAccordionChange = (accordionId) => {
    const currentIndex = expandedAccordions.indexOf(accordionId);
    const newExpanded = [...expandedAccordions];

    if (currentIndex === -1) {
      newExpanded.push(accordionId);
    } else {
      newExpanded.splice(currentIndex, 1);
    }

    setExpandedAccordions(newExpanded);
  };

  return (
    <>
      {!isLoading ? (
        <div>
          {openForm && (
            <AddSeriesModal
              openModal={handleOpenForm}
              closeModal={handleCloseForm}
            />
          )}
          {open && (
            <TrailerModal openModal={handleOpen} closeModal={handleClose} />
          )}
          <div className={classes.container}>
            {seriesPageDetails ? (
              <>
                    {/* <AdsScript src="https://alwingulla.com/88/tag.min.js" zone="52356" /> */}
                <Helmet>
                  <title>
                    {seriesPageDetails.name} - Movis.ng | Download Movies,
                    Download TV Series
                  </title>
                  <meta
                    name="description"
                    content={seriesPageDetails.description}
                  />
                  <meta property="og:title" content={seriesPageDetails.name} />
                  <meta
                    property="og:description"
                    content={seriesPageDetails.description}
                  />
                  <meta
                    property="og:image"
                    content={seriesPageDetails.image_url}
                  />
                  <meta property="og:url" content={url} />
                  <meta name="twitter:title" content={seriesPageDetails.name} />
                  <meta
                    name="twitter:description"
                    content={seriesPageDetails.description}
                  />
                  <meta
                    name="twitter:image"
                    content={seriesPageDetails.image_url}
                  />
                </Helmet>
                <div className="min-h-[80vh]">
                  <div className="flex flex-col items-center justify-center my-3">
                    <a href="https://t.me/movisng" target="_blank">
                      <img src={telegramBanner} className="rounded-lg" />
                    </a>
                  </div>
                  <div className="hero-content flex-col lg:flex-row">
                    <img
                      src={seriesPageDetails.image_url}
                      alt={seriesPageDetails.name}
                      className="w-60 rounded-lg shadow-2xl"
                    />
                    <div>
                      <h3 className="text-3xl font-bold">
                        {seriesPageDetails.name}
                      </h3>
                      <p className="py-2 text-14px">
                        {seriesPageDetails.description}
                      </p>
                      <div className="py-2">
                        {seriesPageDetails.trailer_url ? (
                          <button
                            className="btn btn-outline border-gray-300 text-gray-300 mx-2"
                            onClick={handleOpen}
                          >
                            <PlayCircle /> Watch Trailer
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="flex items-center justify-center">
                <span className="loading loading-spinner text-warning text-6xl"></span>
              </div>
            )}
          </div>
          <div className="flex flex-col items-center justify-center">
            <h5 className="text-2xl font-bold text-white my-1">
              Download Episodes
            </h5>

            {isLoggedIn && !isScreenSizeSmall ? (
              <button
                className="text-primaryColor border border-primaryColor px-4 py-2 my-3 flex items-center"
                onClick={handleOpenForm}
              >
                Add Season/Episode
                <Plus className="ml-2" />
              </button>
            ) : (
              ""
            )}
          </div>
          <div
            className={
              isScreenSizeSmall ? classes.episodesMobile : classes.episodes
            }
          >
            {seasonsList
              .slice() // Create a shallow copy of the seasonsList
              .sort((a, b) => b.season.season_number - a.season.season_number) // Sort seasons in reverse order
              .map((seasonData) => {
                // Sort episode data in reverse order
                const sortedEpisodeNumbers = [...seasonData.episodes]
                  .map((episode) => episode.episode_number)
                  .sort((a, b) => a - b);
                const sortedEpisodeDownloadLink = [...seasonData.episodes]
                  .map((episode) => episode.download_url)
                  .sort((a, b) => a - b);

                return (
                  <EpisodesList
                    key={seasonData.season.season_number}
                    seasonTitle={seasonData.season.season_number}
                    seasonNumber={seasonData.season.season_number}
                    episodeNumbers={sortedEpisodeNumbers}
                    episodeDownloadLink={sortedEpisodeDownloadLink}
                    isFirstSeason={expandedAccordions.includes(
                      seasonData.season.id
                    )}
                    onAccordionChange={() =>
                      handleAccordionChange(seasonData.season.id)
                    }
                  />
                );
              })}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <span className="loading loading-spinner text-warning text-6xl"></span>
        </div>      )}
    </>
  );
};
export default SeriesDetails;

const useStyles = makeStyles((theme, isScreenSizeSmall) => ({
  container: {
    marginTop: "15px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    // alignItems: "flex-start",
    flexWrap: "wrap",
    width: "auto",
  },
  poster: {
    maxHeight: "50vh",
  },
  MovieDetails: {
    marginLeft: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  movieTitle: {
    fontSize: "30px",
  },
  movieDescription: {
    width: "40vw !important",
  },
  movieDescriptionMobile: {
    width: "95vw !important",
  },
  movieStats: {
    display: "flex",
    flexDirection: "row",
  },
  downloadButton: {
    marginTop: "0px",
  },
  episodes: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto", // Center horizontally
    width: "70vw",
    marginBottom: "10px"
  },
  episodesMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto", // Center horizontally
    width: "100vw",
  },
  addButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "right",
    marginTop: "0px",
    width: "auto",
  },
  genres: {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
    marginTop: "5px",
    marginBottom: "10px",
  },
}));
