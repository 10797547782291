import React from "react";

const Contact = () => {
  return (
    <div className="container mx-auto px-4 flex flex-col justify-center items-center min-h-[80vh]">
      <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
      <p className="mb-4">For enquiries, complaints, or requests, please reach us via:</p>
      <ul className="list-disc ml-6 mb-4">
        <li>Email: <a href="mailto:info@movis.ng">info@movis.ng</a></li>
        <li>Telegram: <a href="https://t.me/movisng">@movisng</a></li>
      </ul>
    </div>
  );
};

export default Contact;
