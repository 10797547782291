import { React, useEffect, useState } from "react";
import MovieCard from "../components/MovieCard";
import { makeStyles } from "@material-ui/styles";
import MockData from "../components/MockData";
import {
  Badge,
  Button,
  Chip,
  Typography,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Sections from "../components/Section";
import TrailerModal from "../components/TrailerModal";
import { Link, useParams } from "react-router-dom";
import { Download, PlayCircle } from "@mui/icons-material";
import { Helmet } from "react-helmet";

const Genres = ({ download_url }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);
  const [moviePageDetails, setMoviePageDetails] = useState();
  const [genreslist, setGenresList] = useState([]);

  const { movieId, name } = useParams();

  const key = process.env.REACT_APP_TMDB_KEY;

  const fetchgenres = `${process.env.REACT_APP_API_URL}/fetch-genres/`;

  useEffect(() => {
    fetch(fetchgenres)
      .then((response) => response.json())
      .then((data) => {
        setGenresList(data);
        // setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);
  
  console.log(genreslist);


  return (
    <>
      <div className={classes.container}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "600", color: "yellow" }}
              >
                Genres:
              </Typography>
              <div className={classes.genres}>
                {genreslist.map((genre) => (
                  <div className={classes.genres}>
                    <Chip label={genre.name} sx={{backgroundColor: "#FFA500" }} />
                  </div>
                ))}
              </div>
            </div>
          </>
  )};
export default Genres;

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "15px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    // alignItems: "flex-start",
    flexWrap: "wrap",
  },
  poster: {
    maxHeight: "50vh",
  },
  Genres: {
    marginLeft: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  },
  movieTitle: {
    fontSize: "30px",
  },
  movieDescription: {
    width: "40vw !important",
  },
  movieDescriptionMobile: {
    width: "95vw !important",
  },
  movieStats: {
    display: "flex",
    flexDirection: "row",
  },
  downloadButton: {
    marginTop: "20px",
  },
  related: {
    display: "flex",
    flexDirection: "row",
  },
  genres: {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
    marginTop: "5px",
    marginBottom: "10px",
  },
}));
