import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Search } from "@mui/icons-material";
import { Plus, X } from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddSeriesModal = ({ openModal, closeModal, download_url }) => {
  const classes = useStyles();

  const [movieFormData, setMovieFormData] = useState({
    season_number: "",
    episode_number: "",
    downloadUrl: "",
  });
  const [formData, setFormData] = useState({});

  const { seriesId } = useParams();

  const key = process.env.REACT_APP_TMDB_KEY;

  const handleChange = (e) => {
    setMovieFormData({
      ...movieFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDownloadUrlChange = (e) => {
    setFormData({
      ...formData,
      downloadUrl: e.target.value,
    });
  };

  const mappedSeriesData = {
    season: {
      season_number: movieFormData.season_number,
      series: seriesId,
      episodes: [
        {
          episode_number: movieFormData.episode_number,
          download_url: formData.downloadUrl,
        },
      ],
    },
  };

  const postseries = `${process.env.REACT_APP_API_URL}/add-season/${seriesId}/`;

  const handleSeriesSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(postseries, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mappedSeriesData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }
      const data = await response.json();
      toast.success("Episode Added successfully!");
      setFormData("");
    } catch (error) {
      toast.error("Episode was not added: " + error);
    }
  };

  return (
    <>
      <ToastContainer />
      {movieFormData ? (
        <Dialog open={openModal} onClose={closeModal} maxWidth="lg">
          <div className={classes.modal}>
            <div>
              <DialogTitle>Add Season/Episode</DialogTitle>
              <DialogContent>
                {movieFormData && (
                  <div className={classes.formFields}>
                    <FormControl>
                      <TextField
                        type="number"
                        name="season_number"
                        size="small"
                        placeholder="Season Number"
                        value={movieFormData.overview}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        type="number"
                        name="episode_number"
                        size="small"
                        placeholder="Episode Number"
                        value={movieFormData.release_date}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <TextField
                        type="text"
                        size="small"
                        name="downloadUrl"
                        value={formData.downloadUrl}
                        placeholder="Episode Download URL"
                        onChange={handleDownloadUrlChange}
                      />
                    </FormControl>
                  </div>
                )}
              </DialogContent>
              <DialogActions
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <Button
                  sx={{
                    color: "red",
                    border: "red solid 1px",
                  }}
                  onClick={closeModal}
                  startIcon={<X size={"15px"} />}
                >
                  Close
                </Button>
                <Button
                  sx={{
                    color: "white",
                    backgroundColor: "orange",
                  }}
                  onClick={handleSeriesSubmit}
                  endIcon={<Plus size={"15px"} />}
                >
                  Add Episode
                </Button>
              </DialogActions>
            </div>
          </div>
        </Dialog>
      ) : (
        <CircularProgress size={20} />
      )}
    </>
  );
};

export default AddSeriesModal;

const useStyles = makeStyles({
  modal: {
    width: "30vw",
  },
  searchField: {
    display: "flex",
    flexDirection: "row",
  },
  formFields: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      marginTop: "15px !important",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
});
