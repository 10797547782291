import { React, useEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import Sections from "../components/Section";
import TrailerModal from "../components/TrailerModal";
import { useParams } from "react-router-dom";

import { Helmet } from "react-helmet";
import { Download, PlayCircle } from "react-feather";
import telegramBanner from "../../assets/images/telegram_banner.gif";
import AdsScript from "../components/AdsScript";
import { DownloadLink } from "../../assets/images";

const MovieDetails = () => {
  const [open, setOpen] = useState(false);
  const [moviePageDetails, setMoviePageDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { movieId, name } = useParams();

  useEffect(() => {
    const fetchMovieDetails = async () => {
      try {
        const fetchdetails = `${process.env.REACT_APP_API_URL}/movie/${movieId}/${name}/`;
        const response = await fetch(fetchdetails);
        if (!response.ok) {
          throw new Error("Failed to fetch movie details");
        }
        const data = await response.json();
        setMoviePageDetails(data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchMovieDetails();
  }, [movieId, name]);

  const url = `https://movis.ng/movies/${movieId}/${name}/`;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="container mx-auto px-1 min-h-[80vh]">
      {!isLoading ? (
        <div>
          {open && (
            <TrailerModal
              openModal={handleOpen}
              closeModal={handleClose}
              downloadLink={moviePageDetails.download_url}
            />
          )}
          <div className="flex flex-col items-center justify-center">
            <>
              <Helmet>
                <title>
                  Download {moviePageDetails.name} - Movis.ng | Download Movies,
                  Download TV Series
                </title>
                <meta
                  name="description"
                  content={moviePageDetails.description}
                />
                <meta property="og:title" content={moviePageDetails.name} />
                <meta
                  property="og:description"
                  content={moviePageDetails.description}
                />
                <meta
                  property="og:image"
                  content={moviePageDetails.image_url}
                />
                <meta property="og:url" content={url} />
                <meta name="twitter:title" content={moviePageDetails.name} />
                <meta
                  name="twitter:description"
                  content={moviePageDetails.description}
                />
                <meta
                  name="twitter:image"
                  content={moviePageDetails.image_url}
                />
              </Helmet>
              <div>
                <div className="flex flex-col items-center justify-center my-3">
                  <a href="https://t.me/movisng" target="_blank">
                    <img src={telegramBanner} className="rounded-lg" />
                  </a>
                </div>
                <div className="hero-content flex-col lg:flex-row">
                  <img
                    src={moviePageDetails.image_url}
                    alt={moviePageDetails.name}
                    className="w-60 rounded-lg shadow-2xl"
                  />
                  <div>
                    <h3 className="text-3xl font-bold">
                      {moviePageDetails.name}
                    </h3>
                    <div className="flex my-2">
                      {moviePageDetails.genres.map((genre) => (
                        <div className="badge badge-accent badge-outline mr-1 ">
                          {genre}
                        </div>
                      ))}
                    </div>
                    <p className="py-1 text-14px text-red-400">
                      Release Date:{" "}
                      {new Date(
                        moviePageDetails.release_date
                      ).toLocaleDateString("en-GB")}
                    </p>
                    <div>
                    <p className="py-2 text-14px">
                      {moviePageDetails.description}
                    </p>
                    </div>
                    {/* <AdsScript /> */}
                    {/* <div className="md:w-[200px] w-[70vw] py-2">
                      <a href="https://acceptablereality.com/b.3BVt0fP/3Wp-v/bJmNV/JlZPDm0I1GNbDskM3fM/jbI_y_LBTPUH0LO/TQceyWMhjAMg" target="_blank">
                      <img src={DownloadLink} />
                      </a>
                    </div> */}
                    <div className="flex py-2">
                      <a
                        href={moviePageDetails.download_url}
                        target="_blank"
                        className="btn bg-primaryColor border-none"
                      >
                        <Download /> Download Movie
                      </a>
                      {moviePageDetails.trailer_url ? (
                        <button
                          className="btn btn-outline border-gray-300 text-gray-300 mx-2"
                          onClick={handleOpen}
                        >
                          <PlayCircle /> Watch Trailer
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                      <p className="py-3 text-12px">
                        <span className="font-bold">Incoming Keywords:</span> Download {moviePageDetails.name}, Download {moviePageDetails.name} Movie,
                        {moviePageDetails.name} Movie Download, Movie Download 
                        {moviePageDetails.name}, {moviePageDetails.name} Full
                        Download, Full download {moviePageDetails.name}
                      </p>
                  </div>
                </div>
              </div>
            </>

            <div className="flex flex-col items-center justify-center">
              <Sections sectionName="You May Also Like:" />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <span className="loading loading-spinner text-warning text-6xl"></span>
        </div>
      )}
    </div>
  );
};
export default MovieDetails;
