import React from "react";

const DCMA = () => {
  return (
    <div className="container mx-auto px-4 py-3 flex flex-col justify-center items-center min-h-[80vh]">
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-3xl font-bold mb-4">DMCA Compliance</h1>
        <p className="mb-4">This website Movis.ng is in compliance with the Digital Millennium Copyright Act ("DMCA") and Online Copyright Infringement Liability Limitation Act ("OCILLA"). Movis.ng's policy is to REMOVE any page at copyright owner's request using the mechanisms mentioned below and infringes upon the copyrights of any party. NOTE that Movis.ng does NOT serve any movies, pirated software, or any other pirated content from its servers. It is just a search engine.</p>
        <h2 className="text-xl font-bold mb-2">Takedown Instructions</h2>
        <ul className="list-disc ml-6 mb-4">
          <li>Remember that this is just a search tool, your content is not hosted here. Please be polite. There is no point in threats.</li>
          <li>Please only provide URLs of the movies you want taken down.</li>
          <li>Takedowns that do not provide URLs with uniquely identifying ID values will take considerably more time to process.</li>
          <li>Avoid sending search query URLs. List specific results you want removed.</li>
          <li>Use your company/business email. Free mailboxes (AOL, Yahoo, Hotmail, Gmail, etc.) will take time to process and verify.</li>
        </ul>
        <p>Email your takedowns in plain text to <a href="mailto:movisng@proton.me">movisng@proton.me</a>.</p>
      </div>
    </div>
  );
};

export default DCMA;
