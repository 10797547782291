import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, CircularProgress, Typography } from "@mui/material";
import MockData from "../components/MockData";
import MovieCard from "../components/MovieCard";
import Sections from "../components/Section";
import RecentlyAdded from "../components/RecentlyAdded";
import error404 from "../../assets/images/404.png";
import { Home } from "react-feather";


const Error404 = () => {
  const classes = useStyles();

  const searchParams = new URLSearchParams(window.location.search);
  const searchQuery = searchParams.get("q");
  const [searchResults, setSearchResults] = useState([]);
  const [resultLoading, setResultLoading] = useState(true)

  const [movieDetails, setMoviesDetails] = useState([]);

  const key = process.env.REACT_APP_TMDB_KEY;

  const searchapi = `${process.env.REACT_APP_API_URL}/search/?name=${searchQuery}`;

  useEffect(() => {
    // Fetch search results based on the search query
    const fetchSearchResults = async () => {
      try {
        const response = await fetch(searchapi);
        const data = await response.json();
        setSearchResults(data);
      } catch (error) {
        console.error(error);
      }
    };

    if (searchQuery) {
      fetchSearchResults();
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  return (
    <div>
    <div className={classes.container}>

    <img src={error404} width="250px" />
      <Typography variant="h5" sx={{marginBottom: '10px'}}>404 Error, Page not found</Typography>
      <Button
                  sx={{
                    color: "black",
                    backgroundColor: "orange",
                    padding: "10px",
                    marginTop: '25px',                    
                  }}
                  href="/"
                >
                  Go to Homepage
                </Button>      {/* {searchResults.length > 0 ? (
        <div className={classes.moviesContainer}>
          {searchResults.map((movie) => (
            <MovieCard
              key={movie.id}
              id={movie.id}
              title={movie.name}
              poster={movie.image_url}
            />
          ))}
        </div>
      ) : (<Typography>{searchResults.length === 0 ? "No Search Result" : "Searching..."}
      </Typography>)} */}
    </div>
    <RecentlyAdded />
    </div>
  );
};
export default Error404;

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "10px",
    margin: "15px",
  },
  moviesContainer: {
    width: "100vw",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    flexWrap: "wrap",
  },
});
