import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Import your components for each route
import Home from './views/pages/Home';
import MovieDetails from './views/pages/MovieDetails';
import Layout from './views/Layout/Layout';
import SearchPage from './views/pages/Search';
import Login from './views/components/login';
import Movies from './views/pages/Movies';
import Genres from './views/pages/Genres';
import Series from './views/pages/Series';
import SeriesDetails from './views/pages/SeriesDetails';
import Error404 from './views/pages/Error404';
import KDrama from './views/pages/K-Drama';
import Bollywood from './views/pages/Bollywood';
import Contact from './views/pages/Contact';
import DCMA from './views/pages/DCMA';

const AppRouter = () => (
  <Router>
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/movies" element={<Movies />} />
      <Route exact path="/k-drama" element={<KDrama />} />
      <Route exact path="/bollywood" element={<Bollywood />} />
\      <Route exact path="/series" element={<Series />} />
      <Route exact path="/genres" element={<Genres />} />
      <Route exact path="/search" element={<SearchPage />} />
      <Route path="/movie/:movieId/:name" element={<MovieDetails />} />
      <Route path="/series/:seriesId/:name/" element={<SeriesDetails />} />
      <Route path="/series/:seriesId/:seasonId/" element={<SeriesDetails />} />
      <Route path="/enter" element={<Login />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/dcma" element={<DCMA />} />
      <Route exact path="*" element={<Error404 />} />
    </Routes>
  </Router>
);

export default AppRouter;
