import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { CircularProgress, Typography } from "@mui/material";
import MockData from "../components/MockData";
import MovieCard from "../components/MovieCard";

const RecentlyAdded = () => {
  const classes = useStyles();

  const [movieDetails, setMoviesDetails] = useState([]);
  const [movielist, setMovieList] = useState([]);

  const itemsPerPage = 5;

  const key = process.env.REACT_APP_TMDB_KEY;

  const api = `https://api.themoviedb.org/3/discover/movie?api_key=${key}`;

  const fetchmovies = `${process.env.REACT_APP_API_URL}/fetch-movies/`;

  useEffect(() => {
    fetch(fetchmovies)
      .then((response) => response.json())
      .then((data) => {
        setMovieList(data.results);
      })
      .catch((err) => console.log(err));
  }, []);

  const hollywoodMovies = movielist
  .filter(
    (movie) => movie.category === "movies" || movie.category === "Movie"
  )
  .slice(0, itemsPerPage);

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <h3 style={{ color: "#FFA500", marginLeft: "25px" }}>
          Recently Added Movies
        </h3>{" "}
      </div>
      {movielist ? (
        <div className={classes.moviesContainer}>
          {hollywoodMovies.map((movie) => (
            <MovieCard
              key={movie.id}
              id={movie.id}
              title={movie.name}
              poster={movie.image_url}
              movieType={movie.movie_type}
            />
          ))}
        </div>
      ) : (
        <CircularProgress size={20} />
      )}
    </div>
  );
};
export default RecentlyAdded;

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "10px",
    margin: "15px",
  },
  moviesContainer: {
    width: "100vw",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    flexWrap: "wrap",
  },
  title: {
    marginTop: "40px",
  },
});
