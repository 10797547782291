import React, { useState, useEffect } from "react";
import MovieCard from "./MovieCard";
import { useParams } from "react-router-dom";

const Sections = ({ sectionName, type, id }) => {
  const [simlarMovies, setSimilarMovies] = useState();
  const { movieId } = useParams();

  const fetchsimilar = `${process.env.REACT_APP_API_URL}/movies/${movieId}/similar/`;

  useEffect(() => {
    fetch(fetchsimilar)
      .then((response) => response.json())
      .then((data) => {
        const limitedSimilarMovies = data.slice(0, 6);
        setSimilarMovies(limitedSimilarMovies);
      });
  }, [fetchsimilar]);

  return (
    <div className="flex flex-col items-center justify-center my-3">
      <div>
        <h5 className="text-2xl font-bold text-white my-3">{sectionName}</h5>
      </div>
      {simlarMovies ? (
        <div className="flex flex-wrap gap-2 items-center justify-center ">
          {simlarMovies.map((data) => (
            <MovieCard
              key={data.id}
              title={data.name}
              poster={data.image_url}
              id={data.id}
              movieType={data.movie_type}
            />
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <span className="loading loading-spinner text-warning text-6xl"></span>
        </div>
      )}
    </div>
  );
};
export default Sections;
